/* .article-div{} */


@media (max-width: 1300px) {
    .right-dowrite-div{
        width: 240px !important;
    }
    .right-dowrite-div2{
        width: 240px !important;
    }
}

@media (max-width: 991px) {
    .right-dowrite-div{
        width: 300px !important;
        top: -25;
        right: -20px !important;
    }
    .right-dowrite-div2{
        width: 300px !important;
        right: -20px !important;
    }
}


@media (max-width: 767px) {
    .article-div{
        width: 100% !important;
        border: none !important;
    }
    .left-dowrite-div{
        margin-right: 0 !important;
    }
}