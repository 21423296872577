.getButton{
    background-color:#7D6AF7;
    color: white;
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
}
.left-card{
    background-color: #f1f1f1;
    border-radius: 20px;
    position: relative;
    /* height: 500px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.faq-card1{
    height: 170px;
    width: 180px;
    background-color: white;
    position: absolute;
    top: -80px;
    left: 20px;
    border-radius: 10px;
}
.faq-card1-icon{
    font-size: 14px;
}

.faq-card2{
    height: 120px;
    width: 180px;
    background-color: white;
    position: absolute;
    top: -20px;
    right: 20px;
    border-radius: 10px;
}

.faq-card3{
    height: 140px;
    width: 92%;
    background-color: white;
    position: absolute;
    bottom: -35px;
    left: 20px;
    border-radius: 10px;
}


@media (max-width: 515px) {
    .faq-card3{
        height: 170px;
        bottom: -65px;
        width: 88%;
    }
    .left-card{
        margin: 0px !important;
    }
    .right-card-faq{
        margin-top: 40px !important;
    }
}