.login-head {
    /* min-height: 100vh;
    background-image: url('../../../assets/images/loginbg.jpg'); */
    background-color: #FFFFFF;
}

.img-logo {
    height: 31px;
    width: 71px;
    margin-top: 15px
}

.login-li{
    font-size: 17px;
    margin-top: 4px;
}

.card-login{
    /* margin-left: 18px;
    margin-right: 18px; */
    width: 80%;
    /* height: 100%; */
    background-color: white;
    border-radius: 9px;
}

.flexClass{
    display: flex;
    justify-content: center;
}

.img-logo2 {
    height: 15px;
    width: 35px;
    margin-top: 10px
}
.btn-login-signup{
    width: 75%;
    height: 33px;
    background: transparent;
    border-radius: 3px;
    border: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-icon{height:30px;width:30px}

.login-text{font-size:14px;color:gray}

.diff-line{
    height:1.5px;
    width:30%;
    margin-top:15px
}

.login-input{
    width: 75%;
    border-radius: 5px;
    border: 1px solid lightgray;
    height: 33px;
    background-color: #FAFAFA;
    padding-left: 15px;
}
.login-input:focus-visible{
    outline: none;
    border-color: blue;
}

.login-input::placeholder{
    color: gray
}

.login-continue-btn{
    width: 75%;
    height: 33px;
    border-radius: 5px;
    background-color: #93B2EF;
    border: none;
    color: white;
}

@media only screen and (max-width: 1400px) {
    .card-login{
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    .card-login{
        width: 100%;
    }
}