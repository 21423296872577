.aicontentbtn{
    border-radius:15px;
    border:2px solid #B6DB86;
    transform:rotate(5deg);
    width:180px
}


@media (max-width: 600px) {
    .aicontent-right-div{
        width: 100% !important;
    }
}