.btn-hero {
    background-color: #725EF8;
    color: white;
    border-radius: 5px;
}

.hero-input-div {
    width: 100%;
    border: 10px solid #f1f1f1;
    /* height: 80vh; */
    border-radius: 30px
}

.head-title {
    background-color: transparent;
    border: none !important;
    padding: 0 !important;
}

.hero-number {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 50%;
    height: 25px;
    width: 25px
}

.hero-div-line {
    margin-top: 12px;
    height: 3px;
    /* width:70%; */
    background-color: #f1f1f1;
    border-radius: 5px;
}

.input-text {
    font-size: 17px;
    font-weight: bold;
    margin: 10px 0 10px 0;
}

.small-switch {
    transform: scale(0.7);
}

.small-switch2 {
    transform: scale(0.5);
}

.herobtn{
    display: flex;
    border-radius: 3px;
    /* width: 150px; */
}
@media (max-width: 767px) {
    .ckeditor-div {
        border: none !important;
        /* height: 50% !important; */
    }

    .hero-input-div {
        padding: 10px !important;
    }

    .ckeditor-head {
        padding: 10px !important;
    }

    .head-title {
        font-size: 13px !important;
        margin-top: 4px !important;
    }
}

@media (max-width: 992px) {
    .hero-title {
        font-size: 40px;
    }

    .hero-title-div {
        width: 95% !important;
    }

    .hero-img {
        top: 0 !important;
        left: 0 !important;
    }

    .hero-img2 {
        top: 0 !important;
        right: 0 !important;
    }

    .hero-img3 {
        /* top:0 !important; */
        left: 0 !important;
    }

    .hero-img4 {
        /* top:0 !important; */
        right: 0 !important;
    }

    .hero-div-line {
        display: none !important;
    }
}