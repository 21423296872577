.nav-text {
    font-size: 18px;
    display: flex;
    gap: 8px;
    position: relative;
}

.nav-angle {
    margin-top: 9px;
}

.nav-angle:hover {
    cursor: pointer;
}

.btn-login {
    background-color: #F1EFFD;
    color: #715CF7;
    width: 80px;
}

.btn-try {
    background-color: #715CF7;
    color: #F1EFFD;
    width: 150px;
}

.nav-popup {
    background-color: white;
    border: 1px solid gray;
    border-radius: 10px;
    height: 100px;
    width: 200px;
    position: absolute;
    left: -100px;
    top: 5vh;
}

/* .nav-popup {
    display: none;
} */

/* Responsive styles */
@media (max-width: 1062px) {
    .nav-end-text {
        gap: 1px !important;
    }
}


@media (max-width: 992px) {
    .nav-center {
        display: none;
    }

    .menu-btn {
        display: block;
        background: none;
        border: none;
        cursor: pointer;
    }

    /* .nav-popup {
        position: absolute;
        background: #fff;
        border: 1px solid #ccc;
        padding: 10px;
        z-index: 1;
    } */

    /* Center logo and show/hide menu icon */
    .col-lg-2 {
        text-align: center;
    }

    .menu-icon {
        display: block;
    }

    .nav-center {
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 10%;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        /* border-top: 1px solid black; */
        width: 100%;
        padding: 10px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        display: none;
        z-index: 1;
    }

    .nav-center.show {
        display: flex;
    }
}

@media only screen and (max-width: 1400px) {
    .nav-angle {
        margin-top: 6px !important;
    }
}

@media only screen and (max-width: 1062px) {
    .nav-center {
        gap: 25px !important;
    }
}