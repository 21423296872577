.ads-head{
    background-image: url("../../../../assets/images/adsImage.PNG");
    /* min-height: 100vh; */
}
.ads-btn{
    background-color:#7D6AF7;
    width: 100%;
    /* height:45px; */
    color: white;
    margin-top: 20px;
    padding: 10px;
}
.ads-btn:hover{
    color: white;
    border:1px solid white
}
.getStarted-btn{
    background-color: white;
    color: #7D6AF7;
    font-weight: bold;
}
.getStarted-btn:hover{
    color: white;
    border:1px solid white;
    /* color: rgba(255, 255, 255, 0.295); */
}

.rtw-img{
    border-top-left-radius:15px;
    border-top-right-radius:15px;
    padding:15px 15px 0 15px;
    background-color:rgba(255, 255, 255, 0.295);
    width:75%
}

@media (max-width: 767px) {
    .rtw-img{
        width: 90%;
    }
}